@tailwind base;
@tailwind components;

[multiple],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
* Box
*/

.box {
  @apply flex flex-1;
}

.box-title h3 {
  @apply font-semibold text-xl ml-2;
}

.box-tools {
  @apply flex items-center justify-between my-3.5;
}

.box-content {
  @apply block rounded-md shadow-sm bg-white flex-1 w-full;
}

.box-title {
  @apply text-gray-900 text-xl leading-tight font-medium flex flex-1 items-center justify-between;
}

.box-body {
  @apply text-gray-700 text-base mb-4;
}
/* 
* Table
*/
.table-box {
  @apply flex flex-col w-full;
}

.table-responsive {
  @apply overflow-x-auto;
}

.table-full {
  @apply align-middle inline-block min-w-full py-2;
}

.table-main {
  @apply shadow-sm overflow-hidden sm:rounded-sm;
}

.table-box .box-tools {
  @apply flex justify-between items-center;
}

.table {
  @apply min-w-full divide-y divide-gray-200;
}

.table thead {
  @apply bg-gray-50;
}

.table-box .table thead th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap;
}

.thead {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap;
}

.table-box .table tbody {
  @apply bg-white divide-y divide-gray-200;
}

.table-box .table tbody td {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}
.table-box .table tbody td.no-p {
  @apply px-6 py-0;
}

/* 
* Status label
*/
.status {
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800;
}

.status .active {
  @apply bg-green-100 text-green-800;
}

.btn {
  @apply text-white mt-1 px-6 py-2.5 font-semibold flex items-center gap-1 justify-center text-xs leading-tight uppercase rounded shadow-sm focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
}

.btn-primary {
  @apply btn bg-blue-500 hover:bg-blue-700 hover:shadow-md;
}

.btn-success {
  @apply btn bg-green-500 hover:bg-green-700 hover:shadow-md;
}

.btn-danger {
  @apply btn bg-red-500 hover:bg-red-700 hover:shadow-md;
}

.btn-warning {
  @apply btn bg-yellow-500 hover:bg-yellow-700 hover:shadow-md;
}
.btn-info {
  @apply btn bg-sky-500 hover:bg-sky-700 hover:shadow-md;
}
.btn-olive {
  @apply btn bg-[#353535] hover:text-logo_color hover:brightness-90 hover:shadow-md;
}

.btn-xs {
  @apply px-1 py-1 text-xs;
}

.btn-sm {
  @apply px-2 py-1.5;
}

.btn-icon {
  @apply w-8 aspect-square rounded-full grid place-items-center;
}

.btn-icon .icon {
  @apply h-3.5 w-3.5 text-white;
}

.btn-md.btn-icon .icon {
  @apply h-4 w-4;
}

.router-link-exact-active.btn-active {
  @apply bg-indigo-700;
}

/* 
  * Form input
  */
.form-box {
  @apply shadow-none overflow-hidden sm:rounded-md;
}
.form-info {
  @apply mt-2 text-xs text-red-600;
}

.form-box-body {
  @apply px-4 py-5 bg-white sm:p-6;
}

.form-box-foot {
  @apply px-4 py-3 bg-gray-50 text-right sm:px-6;
}

.form-grid {
  @apply grid grid-cols-6 gap-6 sm:gap-3;
}

.form-row {
  @apply w-full;
}

.form-label {
  @apply text-sm inline-block my-2 font-semibold text-gray-700;
}

.form-input {
  @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:outline-none;
}

.form-input:read-only {
  @apply bg-gray-100 focus:bg-transparent;
}

.name-input {
  @apply block w-full border-solid border-stone-400 rounded bg-white outline-none focus:outline-none;
}

.form-select {
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  @apply mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.form-area {
  @apply shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md;
}

.input-file {
  @apply block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-md transition ease-in-out m-0 focus:focus:bg-white focus:border-indigo-500 focus:border-opacity-100;
}

.form-group {
  @apply mt-1 flex rounded-md shadow-sm;
}

.form-prefix {
  @apply inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm;
}

.form-g-input {
  @apply focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full focus:outline-none rounded-none rounded-r-md sm:text-sm border-gray-300;
}

.form-c-check {
  @apply flex items-center;
}

.form-check {
  @apply focus:ring-indigo-500 focus:ring-1 h-4 w-4 text-indigo-600 border-gray-300 rounded;
}

.form-radio-label {
  @apply ml-3 block text-sm font-medium text-gray-700;
}

.form-radio {
  @apply focus:ring-indigo-500 focus:ring-1 h-4 w-4 text-indigo-600 border-gray-300;
}

.form-error {
  @apply text-red-500 text-sm flex items-center;
}

.form--group {
  @apply mt-1 relative rounded-md shadow-sm;
}
.form--prefix {
  @apply absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none;
}
.form--prefix span {
  @apply text-gray-500 sm:text-sm;
}
.form--group--input {
  @apply focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md;
}
.form--group--suffer {
  @apply absolute inset-y-0 right-0 flex items-center;
}
.react-select {
  @apply appearance-none text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat  border-solid border-stone-400 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
}
.form--group--suffer select {
  /* background-image: url(
    data:image/svg + xml,
    %3csvgxmlns="http://www.w3.org/2000/svg"fill="none"viewBox="0 0 20 20"%3e%3cpathstroke="%236b7280"stroke-linecap="round"stroke-linejoin="round"stroke-width="1.5"d="M6 8l4 4 4-4"/%3e%3c/svg%3e
  ); */
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  @apply focus:ring-indigo-500 focus:border-indigo-500 h-full focus:outline-none py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md;
}
/* 
* Pagination
*/

/* 
* List
*/
.list-box {
  @apply flex;
}

.list-main {
  @apply bg-white rounded-md border border-gray-200 text-gray-900;
}

.list-main li {
  @apply px-6 py-2 border-b border-gray-200 w-full;
}

.list-main li:first-child,
.list-main li:last-child {
  @apply rounded-t-lg;
}

.list-main li:last-child {
  @apply border-transparent;
}

@tailwind utilities;
